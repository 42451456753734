@import url("https://fonts.googleapis.com/css2?family=Jura:wght@500&display=swap&text=CryspescaN");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply bg-gray-800 h-full;
  }
}

@layer utilities {
  .neon-text {
    color: #8be9ed;
    text-shadow: 0 0 2px #0a4a4a, 0 0 5px #a1e8eb, 0 0 10px #dde6e6;
  }

  .pulsate {
    animation: pulsate 1.5s infinite alternate;
  }

  @keyframes pulsate {
    100% {
      text-shadow: 0 0 2px #fff, 0 0 6px #fff, 0 0 10px #fff, 0 0 20px #0fa,
        0 0 40px #0fa, 0 0 45px #0fa, 0 0 50px #0fa, 0 0 75px #0fa;
    }
    0% {
      text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff, 0 0 5px #0fa,
        0 0 23px #0fa, 0 0 28px #0fa, 0 0 25px #0fa, 0 0 40px #0fa;
    }
  }
}
